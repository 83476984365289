.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .bg-color{
    background-color: "#200f55";
  }
  @import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@400;500;600&display=swap');
  
  @font-face {
      font-family: 'MyCustomFont';
      src: url('./SFProDisplay-Bold.ttf') format('truetype');
    }
  
  :root {
      --bg1: #151828;
      --bg2: #1B1F34;
      --body: #959DCC;
      --brand: #FFAB76;
      --white: #fff;
  }
  
  *, ::after, ::before {
      box-sizing: border-box;
      color: white;
  }
  
  img, svg {
      vertical-align: middle;
  }
  
  
  body{
      font-family: "Inter",'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      color: var(--body);
      line-height: 1.8;
      background-color: #210E53 !important;
      /* background-color: var(--bg1);   */
  }
  
  h1,h2,h3,h4,h5,h6{
      font-family: "Dela Gothic One", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;;
      color: white;
  
  }   
  a{
      color: var(--body);
      text-decoration: none;
  }
  a:hover{
      color: var(--brand);
  }
  
   .navbar{
      background-color: #190E36;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
   }
  
  .nav_div{
      padding: 3px 1px 7px 9px;
      background-color: #2c2363;
      margin-left: 10px;
      border-radius: 3px;
      /* align-items: center !important; */
      border-radius: 7px;
      text-align: left !important;
  }
  
  svg{
      text-align: center !important;
      position: relative;
      left: -4px;
  }
  
  .nav_logo{
      text-align: right !important;
  }
  
  
  .play_btn{
      background-color: #FFE536;
      color: black;
      font-weight: bolder;
      font-size: larger;
      padding: 3px 25px 3px 25px;
      border-radius: 5px;
      width: 100px;
      text-align: center !important;
      border-radius: 10px;
      /* box-shadow: -5px -2px 2px 2px rgba(155, 43, 9, 0.2) !important; */
      box-shadow: -3px 1px 18px -1px rgba(199, 50, 50, 0.71) !important;
  }
  
  .hero_div{
      width: 60% !important;
      /* border: 1px solid red; */
  }
  
  .hero{
      min-height: 87vh;
      background-position: center;
      background-size: cover;
      align-items: center;
      display: flex;
      background-color: #210E53;
  }
  
  .hero img{
      text-align: center;
      color: #FCE45C;
      width: 100% !important;
      /* text-shadow: 7px 7px black; */
  }
  .hero_text_div{
      margin-top: 1rem !important;
  }
  .hero_text{
      text-align: center !important;
      font-weight: 800 !important;
      font-size: x-large !important;
      color: white !important;
      width: 80%;
      margin: 0px auto !important;
  }
  .percent{
     color: #FFE536; 
  }
  .entire{
      text-decoration: underline;
  }
  
  .hero_btn{
      text-align: center;
      margin-top: 1.7rem !important;
  }
  
  .hero_btn button{
      background-color: #7752F0;
      color: white;
      text-shadow: 2px 2px black;
      font-weight: bolder !important;
      border-radius: 10px;
      font-size: large;
      padding: 5px 13px 5px 13px ;
      transition: 0.2s ease-in;
  }
  .hero_btn button:hover{
      background-color: #FFE536;
      color: #7752F0 !important;
      text-shadow: 0px 0px black
  }
  
  
  
  /* staking page */
  .staking{
      background-position: center;
      background-size: cover;
      align-items: center;
      /* display: flex; */
      background-color: #210E53;
      height: 100%;
      padding-top: 40px;
  
  }
  
  .staking_scd_div{
      width: 60%;
      margin: 40px auto !important;
  }
  .staking img{
      text-align: center;
      color: #FCE45C;
      width: 100% !important;
  }
  .img_div{
      text-align: center;
  }
  .countdown_div{
      margin-top: 4rem;
  }
  .countdown{
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: 700 !important;
      font-size: 40px !important ;
  }
  .staking_payout_div{
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
  }
  .staking_payout_div h6{
      font-size: 24.0749px;
      line-height: 33px;
      vertical-align: baseline;
      letter-spacing:1.20375px;
      font-weight: 400;
  }
  
  .payout_main_div{
      background-color: #322068;
      border-radius: 13px !important;
      margin-top: 1.5rem !important;
      /* border: 1px solid red; */
  }
  .staking_payout_2nddiv{
      background-color: #362664;
      text-align: center;
      width: 90%;
      margin: 0px auto;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 13px !important;
  }
  .staking_payout_2nddiv h1{
      word-spacing: 20px;
      font-weight: 400;
      vertical-align: baseline;
      letter-spacing: 2.498px;
      font-stretch: 100%;
      font-size: 49.9601px;
  }
  
  .payout_point_div{
      background-color: #322068;
      margin-top: 30px;
      border-radius: 13px !important;
  
  }
  .payout_point_div ul{
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 60px !important;
      padding-right: 10px !important;
  }
  .payout_point_div li{
      font-size: 24.0749px;
      line-height: 37px;
      vertical-align: baseline;
      letter-spacing: normal;
      font-style: normal;
      font-stretch: 100%;
      font-weight: bold;
  }
  .your_wallet_div{
      margin-top: 5rem;
  }
  .your_wallet_div p{
      line-height: 50px;
      font-size: 40px !important;
      vertical-align: baseline;
      letter-spacing: 0.6px;
      word-spacing: 0.6px;
      font-weight: bolder !important;
      font-optical-sizing: auto !important;
  }
  
  .copy_icon {
      max-width: 10% !important;
      margin: 0px right;
      cursor: pointer;
  
  }
  .wallet_buttons{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }
  .address_2nddiv{
      background-color: #2C1C5D;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 7px !important;
      border-radius: 7px;
      display: flex !important;
      flex-direction: row;
      justify-content: space-around !important;
  }
  .address_2nddiv1{ 
      background-color: #2C1C5D;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      padding-left: 7px !important;
      border-radius: 7px;
      height: 41px !important;
  }
  .address_2nddiv div{
      font-weight: 700 !important;
  }
  .address_div{
  margin-top: 2.7rem !important;
  }
  .address_div_heading{
      color: #7D7199;
      font-weight: 900 !important;
      letter-spacing:0.733335px !important;
      margin-top: 5px !important;
      line-height:20px !important;
      font-stretch:100% !important;
      vertical-align:baseline;
      letter-spacing: -0.9px !important;
  }
  .address_div_img{
      max-width: 75% !important;
      margin: 0px right;
      height:45px;
  }
  .tierBtn{
      line-height: 25px !important;
      letter-spacing: 1.06667px !important;
      font-weight: bold !important;
      font-optical-sizing: auto;
      font-stretch: 100%;
      font-size: 21.3334px;
      /* font-family: 'Times New Roman', Times, serif !important; */
      padding: 7px 20px 7px 20px;
      background-color: #2C1C5D !important;
      text-shadow: 2px 2px black;
      border: none;
      outline: none;
      border-radius: 10px;
      
  }
  .eligible{
      line-height: 25px !important;
      letter-spacing: 1.06667px !important;
      font-weight: bold !important;
      font-optical-sizing: auto;
      font-stretch: 100%;
      font-size: 21.3334px;
      /* font-family: 'Times New Roman', Times, serif !important; */
      padding: 6px 13px 7px 13px;
      background-color: #42FF8A !important;
      text-shadow: 2px 2px black;
      border: none;
      outline: none;
      border-radius: 10px;
  }
  .eligible-timer{
    line-height: 25px !important;
      letter-spacing: 1.06667px !important;
      font-weight: bold !important;
      font-optical-sizing: auto;
      font-stretch: 100%;
      font-size: 21.3334px;
      /* font-family: 'Times New Roman', Times, serif !important; */
      padding: 6px 13px 7px 13px;
      text-shadow: 2px 2px black;
      background-color: #FFE535 !important;
      border: none;
      outline: none;
      border-radius: 10px;
  }

  .stake_btn_text {
        color: #7D7199;
        font-weight: 400 !important;
        letter-spacing: 0.733335px !important;
        margin-bottom: 10px !important;
        line-height: 35px !important;
        font-stretch: 100% !important;
        vertical-align: baseline;
        letter-spacing: 0.1px !important;
padding-left: 9px;  }

  .stake_div{
      margin-top: 1.5rem !important;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: #2C1C5D;
      margin-bottom: 7rem;
      border-radius: 5px;
      
      /* border: 1px solid red; */
  }
  
  
  /* .stake_btn{
      padding: 3px 25px 3px 25px;
      background-color: #201051;
      color: white;
      font-weight: bolder;
      outline: none !important;
      border: none !important;
      border-radius: 3px;
  
  }
  .unstake_btn{
      padding: 3px 25px 3px 25px;
      background-color: #201051;
      color: #695992;
      font-weight: bolder;
      outline: none !important;
      border: none !important;
      border-radius: 3px;
  } */
  
  .stake_btn, .unstake_btn {
      padding: 10px 30px 10px 30px;
      /* background-color: #201051; */
      color: white;
      font-weight: 900 !important;
      outline: none !important;
      border: none !important;
      border-radius: 3px;
      /* font-weight: bolder !important; */
      cursor: pointer;
      font-size: 20px;
      border-radius: 10px;
  }
  .unstake_btn{
      margin-left: 10px;
  }
  
  .dull {
      background-color: #201051;
      color: #7D7199;
  }
  
  .highlighted {
      background-color: #41336D;
      color: white;
  
  }
  
  .search_main_div{
      /* background-color: #362665; */
      margin-top: 1.7rem;
      /* padding-top: 10px; */
      padding-left: 1rem;
      /* padding-bottom: 15px;     */
      padding-right: 1rem;
      /* border: 1px solid red; */
  }
  .search_main_div p{
      color: #a798c9;
      font-weight: 700 !important;
      font-size: 25px !important;
  }
  .search_main_div input{
      width: 100%;
      background-color: #180A3B !important;
      border: none;
      outline: none;
      padding-top: 9px;
      padding-bottom: 9px;
      padding-left: 4px;
      border-radius: 5px;
      position: relative;
      left: 0px;
      top: 8px;
      bottom: 15px;
  }
  
  .stake_2nd_btn{
      background-color: #FCE45C;
      color: black;
      font-weight: bolder !important;
      font-size: 27px;
      border-radius: 5px;
      max-width: 100px !important;
      text-align: center !important;
      border-radius: 10px;
      margin-top:20px;
      cursor: pointer;
      position: relative;
      bottom: 18px;
      color: white;
      text-shadow: 2px 3px 2px black;
      padding: 0px 20px 5px 20px;
      outline: none;
      border: none;
      width: 100% !important;
      max-width: 100% !important;
      text-align: center !important;
      box-shadow: 0px 5px 0px rgba(225, 228, 47, 0.2) !important;
  }
  .distribution_img{
      margin-top: 2rem;
      padding-top: 1rem;
      padding-left: 1rem;
      padding-bottom: 15px;
      border-radius: 13px;
      background-color: #362665;
      /* width: 70%; */
      /* border: 1px solid red; */
  }
  .distribution_img img{
      max-width: 75%;
  }
  
  .row.stake-div {
    margin-top: -25px !important;
}
  .stake_icon{
      text-align: left;
      margin-bottom: 8px;
      /* border: 1px solid red; */
      /* width: 20% !important; */
  }
  @media screen and (max-width: 991px) {
   
      .wallet_buttons{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
      }
      .address_2nddiv{
          background-color: #2C1C5D;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          padding-left: 7px !important;
          border-radius: 7px;
          display: flex !important;
          flex-direction: row;
          justify-content: space-around !important;
          /* border: 1px solid red; */
      }
      .stake_icon{
          width: 50% !important;
          margin: 0px auto;
      }
      .copy_icon img{
          max-height: 30px;
      }
    }
  
  
  